import React from "react";
import project1 from "../../assets/completed/1.jpg";
import project2 from "../../assets/completed/2.jpg";
import project3 from "../../assets/completed/3.jpg";
import project4 from "../../assets/completed/4.jpg";
import project5 from "../../assets/completed/5.jpg";
import project42 from "../../assets/completed/INTER4.jpg";
import project32 from "../../assets/completed/INTER3.jpg";
import project52 from "../../assets/completed/INT5.jpg";
import ongoing1 from "../../assets/ongoing1.jpeg";
import ongoing12 from "../../assets/ongiong/H1.jpg";
import ongoing13 from "../../assets/ongiong/HOU1.jpg";
import ongoing5 from "../../assets/ongiong/H3.jpg";
import ongoing6 from "../../assets/ongiong/H4.jpg";
import ongoing3 from "../../assets/ongiong/H2.jpg";
import ongoing4 from "../../assets//ongiong/H2S.jpg";
import upcomingProjectTower from "../../assets/upcoming/DNNTOWER.jpg";
import upcomingProjectTower2 from "../../assets/upcoming/TOWER NIGHT.jpg";
import upcomingProject1 from "../../assets/upcoming/H1.jpg";
import upcomingProject2 from "../../assets/upcoming/H2.jpg";
import upcomingProject3 from "../../assets/upcoming/H3.jpg";
import ProjectCard from "../Home/ProjectCard";

const Projects = () => {
  return (
    <section className="py-12 bg-slate-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-medium text-center mb-6">Our Projects</h2>
        <div className="flex flex-wrap gap-8 justify-center lg:justify-between flex-grow">
          <div className=" w-80 h-full shadow-2xl">
            <ProjectCard
              project={{
                name: "Luxury Living",
                images: [project2, project5, project52],
                description:
                  "A collection of prestigious homes and high-end residential properties. Designed with luxury in mind, these homes offer the ultimate in style, comfort and elegance.",
              }}
            />
          </div>
          <div className=" w-80 h-full shadow-2xl">
            <ProjectCard
              project={{
                name: "Elevated Spaces",
                images: [project1, project4, project42],
                description:
                  "Discover the difference of elevated living with these exclusive properties that are designed to enhance the quality of life and provide a unique perspective of the world around you.",
              }}
            />
          </div>
          <div className=" w-80 h-full shadow-2xl">
            <ProjectCard
              project={{
                name: "Modern Masterpieces",
                images: [project3, project32],
                description:
                  "These contemporary structures are the epitome of modern design, blending form and function to create stunning living spaces. From clean lines to bold statements, these homes are perfect for those who appreciate the finer things in life.",
              }}
            />
          </div>
        </div>
      </div>
      <section className="py-12">
        <div className="container mx-auto">
          <h2 className="text-3xl font-medium text-center mb-6">
            Our Ongoing Projects
          </h2>
          <div className="flex flex-wrap gap-8 justify-center lg:justify-between flex-grow">
            <div className=" w-80 shadow-xl">
              <ProjectCard
                project={{
                  name: "Ongoing Project",
                  images: [ongoing1, ongoing12, ongoing13],
                  description:
                    "Discover our latest projects under construction! Our team of experienced professionals is dedicated to building homes and buildings that not only meet but exceed your expectations. With a focus on quality craftsmanship and attention to detail, these houses and projects are being constructed with the utmost care and precision. From the design stage to the final touches, our team is working hard to bring your dream home to life. Be a part of our journey and take a sneak peek at what's coming soon! ",
                }}
              />
            </div>
            <div className=" w-80 shadow-xl">
              <ProjectCard
                project={{
                  name: "Ongoing Project",
                  images: [ongoing3, ongoing4],
                  description:
                    "Discover our latest projects under construction! Our team of experienced professionals is dedicated to building homes and buildings that not only meet but exceed your expectations. With a focus on quality craftsmanship and attention to detail, these houses and projects are being constructed with the utmost care and precision. From the design stage to the final touches, our team is working hard to bring your dream home to life. Be a part of our journey and take a sneak peek at what's coming soon! ",
                }}
              />
            </div>
            <div className=" w-80 shadow-xl">
              <ProjectCard
                project={{
                  name: "Ongoing Project",
                  images: [ongoing5, ongoing6],
                  description:
                    "Discover our latest projects under construction! Our team of experienced professionals is dedicated to building homes and buildings that not only meet but exceed your expectations. With a focus on quality craftsmanship and attention to detail, these houses and projects are being constructed with the utmost care and precision. From the design stage to the final touches, our team is working hard to bring your dream home to life. Be a part of our journey and take a sneak peek at what's coming soon! ",
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="container mx-auto">
          <h2 className="text-3xl font-medium text-center mb-6">
            Our Upcoming Projects
          </h2>
          <div className="flex flex-wrap gap-8 justify-center lg:justify-between flex-grow">
            <div className=" w-80 shadow-xl h-full">
              <ProjectCard
                project={{
                  name: "The Tower",
                  images: [upcomingProjectTower, upcomingProjectTower2],
                  description:
                    "A modern high-rise building, The Tower, boasts panoramic views of the city and luxurious amenities. With its prime location, this project is perfect for those who want to live in the heart of the city.",
                }}
              />
            </div>
            <div className=" w-80 shadow-xl h-full">
              <ProjectCard
                project={{
                  name: "Minimalist Marvel",
                  images: [upcomingProject1, upcomingProject2],
                  description:
                    "Embrace simplicity and sophistication with this beautiful minimalist house. Featuring clean lines, neutral colors, and a modern open floor plan, this home is perfect for those who appreciate minimalistic design.",
                }}
              />
            </div>
            <div className=" w-80 shadow-xl h-full">
              <ProjectCard
                project={{
                  name: "Contemporary Abode",
                  images: [upcomingProject3, upcomingProject3],
                  description:
                    "Experience luxury living in this stunning contemporary home, designed with sleek lines, ample natural light and top-of-the-line features. Your dream home awaits you.",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Projects;
