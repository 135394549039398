import React from "react";
import ProjectCard from "../Home/ProjectCard";
import daudImg from "../../assets/daud.jpeg";
import NasirImg from "../../assets/Nasir.jpeg";
import hassanImg from "../../assets/hassan.jpeg";

const About = () => {
  return (
    <section className="py-12  bg-slate-50">
      <div className="container mx-auto">
        <h2 className="text-3xl font-medium text-center mb-6">About Us</h2>
        <p className="text-lg mb-8 text-center">
          DNN Estates is a leading real estate company that specializes in
          industrial, retail, and office properties. We are committed to
          providing our clients with the best solutions for their real estate
          needs.
        </p>
        {/* <h3 className="text-lg font-medium text-center mb-6">Our Team</h3>
        <div className="flex flex-wrap justify-center lg:justify-between gap-5 items-center ">
          <ProjectCard
            project={{
              name: "Nasir Mehmood Khan",
              image: NasirImg,
              description: " CEO DNN Estates (Pvt) Ltd.",
            }}
          />
          <ProjectCard
            project={{
              name: "Daud Shah ",
              image: daudImg,
              description: "Director Operations DNN Estates (Pvt) Ltd.",
            }}
          />
          <ProjectCard
            project={{
              name: "Mutaqarib ul Hasan ",
              image: hassanImg,
              description: "Director Finance & HR DNN Estates (Pvt) Ltd",
            }}
          />
        </div> */}
      </div>
    </section>
  );
};

export default About;
