import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProjectCard = ({ project }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {project.images.map((img, index) => (
        <div
          key={index}
          className="bg-white shadow-xl outline-none   w-72  p-6 rounded-lg"
        >
          <img
            src={img}
            alt={project.name}
            className="w-full h-64 rounded-lg mb-4 object-cover"
          />
          <h3 className="text-lg font-medium pb-2">{project.name}</h3>
          <p className="text-sm text-justify">{project.description}</p>
        </div>
      ))}
    </Slider>
  );
};

export default ProjectCard;
