import "./App.css";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes/Routes";
// import Footer from "./Components/Footer/Footer";
// import Header from "./Components/Navbar/Navbar";
// import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="  ">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
