import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/dnnlogo.jpeg";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <header className="bg-primary text-white shadow-sm p-4 flex h-28">
      <div className="container mx-auto flex items-center justify-between">
        <Link to="/" className="text-lg flex items-center gap-3 font-medium">
          <img
            className=" w-20 rounded-sm bg-white text-primary"
            src={logo}
            alt="DNN estates logo "
          />
          DNN Estates (Pvt) Ltd.
        </Link>
        <nav className="hidden md:block">
          <Link to="/" className="px-4 py-2 text-sm font-medium">
            Home
          </Link>
          <Link to="/projects" className="px-4 py-2 text-sm font-medium">
            Projects
          </Link>
          <Link to="/about" className="px-4 py-2 text-sm font-medium">
            About Us
          </Link>
          {/* <Link to="/contact" className="px-4 py-2 text-sm font-medium">
            Contact Us
          </Link> */}
        </nav>
        <button className="md:hidden" onClick={() => setNavOpen(!navOpen)}>
          <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path
              fillRule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
        <div
          className={`flex flex-col right-4 top-20 px-4 rounded-lg text-primary z-20 absolute bg-white  ${
            navOpen ? "block" : "hidden"
          }`}
        >
          <NavLink
            to="/"
            onClick={() => setNavOpen(false)}
            className="p-4  font-medium hover:text-white hover:bg-primary"
          >
            Home
          </NavLink>
          <NavLink
            to="/projects"
            onClick={() => setNavOpen(false)}
            className="p-4  font-medium hover:text-white hover:bg-primary"
          >
            Projects
          </NavLink>
          <NavLink
            onClick={() => setNavOpen(false)}
            to="/about"
            className="p-4  font-medium hover:text-white hover:bg-primary"
          >
            About
          </NavLink>
          {/* <NavLink
            onClick={() => setNavOpen(false)}
            to="/contact"
            className="p-4  font-medium hover:text-white hover:bg-primary"
          >
            Contact
          </NavLink> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
