import React from "react";
import upcomingProject from "../../assets/upcomingProject.jpeg";
import project1 from "../../assets/project1.jpeg";
import project2 from "../../assets/project2.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgImage from "../../assets/bgImage.jpg";
import bgImage2 from "../../assets/bgImage2.jpeg";
import bgImage3 from "../../assets/bgImage3.jpg";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2500,
  };
  return (
    <div className=" grid  items-center font-sans ">
      <section className=" bg-cover grid  bg-center ">
        <div className="relative w-screen lg:w-[98.75vw]    z-0">
          <div className="text-center text-white select-none absolute top-[45%] left-0 w-full  z-20">
            <h1 className="text-4xl font-bold">
              Welcome to DNN Estates (Pvt) Ltd.
            </h1>
            <p className="text-xl font-light">
              Real estate solutions for a better tomorrow
            </p>
          </div>
          <Slider {...settings}>
            <div>
              <img
                src={bgImage}
                alt="background of DNN estates Project"
                className="  object-cover w-full h-[80vh] "
              />
              <div className="  flex w-full items-center absolute top-0 bg-[#190e3577] z-0 justify-center  h-[80vh]"></div>
            </div>
            <div>
              <img
                src={bgImage2}
                alt="background of DNN estates Project"
                className="  h-[80vh]  w-full object-cover  "
              />
            </div>
            <div>
              <img
                src={bgImage3}
                alt="background of DNN estates Project"
                className=" h-[80vh]  w-full  object-cover "
              />
            </div>
          </Slider>
        </div>
        <div className="grid px-5 lg:grid-cols-2 gap-3 py-10 items-center">
          <p className="text-lg text-justify  font-semibold">
            DNN Estates is a premier real estate company that specializes in
            building houses and buildings that cater to the unique needs and
            desires of their customers. With a team of experienced
            professionals, they are committed to providing their clients with
            the best solutions for their real estate needs. From the design and
            planning stages to the final construction and delivery, DNN Estates
            ensures that every detail is carefully considered and executed to
            the highest standards. Their portfolio of projects showcases their
            attention to detail, quality craftsmanship and exceptional customer
            service. Whether you are looking for a new home or a commercial
            building, DNN Estates has the expertise and resources to bring your
            vision to life. With DNN Estates, you can be sure that your next
            building project will be a success.
          </p>
          <div>
            <img
              className=" rounded-sm rounded-tl-3xl"
              src={upcomingProject}
              alt="Upcoming Project of DNN"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
