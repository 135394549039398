import React from "react";
import logo from "../../assets/dnnlogo.jpeg";

const Footer = () => {
  return (
    <footer className="bg-primary text-white py-16">
      <div className="container mx-auto flex items-center justify-between   flex-wrap">
        <div className="w-full md:w-4/12 px-4 ">
          <h3 className="font-medium text-lg mb-2">DNN Estates (Pvt) Ltd.</h3>
          <p className="text-sm">Real estate solutions for a better tomorrow</p>
        </div>
        <div className="w-full md:w-4/12 px-4">
          <h3 className="font-medium text-lg mb-2">Quick links</h3>
          <ul className="list-unstyled text-sm">
            <li className="mb-2">
              <a href="/" className="text-white">
                Home
              </a>
            </li>
            <li className="mb-2">
              <a href="/projects" className="text-white">
                Projects
              </a>
            </li>
            <li className="mb-2">
              <a href="/about" className="text-white">
                About Us
              </a>
            </li>
            {/* <li className="mb-2">
              <a href="/contact" className="text-white">
                Contact Us
              </a>
            </li> */}
          </ul>
        </div>
        <div className="w-full md:w-4/12 px-4">
          <h3 className="font-medium text-lg mb-2">Contact Us</h3>
          <ul className="list-unstyled text-sm">
            <li className="mb-2">
              <a href="#" className="text-white">
                Email: dnnestatesprivatelimited@gmail.com
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="text-white">
                Tel: 051-2231260
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="text-white">
                Address: DNN Estates Head Office, Shahpur Chowk, Near Good Day
                Mart, Main Simly Dam Road, Bara Kahu, Islamabad.
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="text-white">
                Youtube: DNN Estates (Pvt) Ltd.
              </a>
            </li>
            <li className="mb-2">
              <a href="#" className="text-white">
                Facebook Page: DNN Estates (Pvt) Ltd.
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="container mx-auto text-center py-2">
        <p className="text-xs">Copyright © 2023 DNN Estates</p>
      </div>
    </footer>
  );
};

export default Footer;
