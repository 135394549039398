import React, { useState, useEffect, useRef } from "react";
import { Routes as DomRoutes, Route, useLocation } from "react-router";
import "./routes.css";

import Home from "../Components/Home/Home";
import Header from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import About from "../Components/AboutUs/AboutUs";
import Projects from "../Components/Projects/Projects";
import ContactUs from "../Components/ContactUs/ContactUs";

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  return (
    <div className="h-screen !grid-rows-[auto_1fr_auto] ">
      <Header />
      <DomRoutes location={location}>
        <Route path="*" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<ContactUs />} />
      </DomRoutes>
      <Footer />
    </div>
  );
};

export default Routes;
